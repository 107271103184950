import React, { useCallback, useLayoutEffect } from "react";
import logo from "./assets/logo.svg";
import bg1 from "./assets/bg1.svg";
import bg2 from "./assets/bg2.svg";

const reviews = [
  {
    name: "Bartek Piotrowski",
    company: "Children's Beds Home",
    text: `SaaS Glue has been supporting us now for over 5 years. We have been designing together a
    software which we now use daily and has become a core of all operations for many departments.
    Without their expertise, professional advise and attention to detail we would never be able to reach
    the point where we are now. Also what's most important is their advice. We may have a lot of Ideas,
    but it is them who make them possible, often suggesting different and better solution. Once again Thank you for your support SaaS Glue`,
  },
  {
    name: "Fraser Ferguson",
    company: "Dimensions",
    text: `Having used SaaS Glue for a few years now, we have always found them to work quickly and effectively on any of our e-Commerce projects. They go over and above, working proactively to keep our orders flowing and sites running, and we look forward to continuing to work with them for many years to come`,
  },
  {
    name: "Philip Wrathall",
    company: "Discount Equestrian",
    text: `SaaS Glue have designed a tailor made API solution for our stock system which filled a much needed requirement that our current ERP software solution could not provide. The solution SaaS Glue developed is used daily for over three years now and is an important part of our operation. In future they will be our go to company for any bespoke integrations or API issues.`,
  },
  {
    name: "Hugo Middleton",
    company: "Men's Society",
    text: `SaaS Glue has worked with us on a number of projects.
      They're quick to respond to requests and we found the work to be good and thorough.
      I would recommend them.`,
  },
  {
    name: "Dr Austin Booth",
    company: "Flex Assessment",
    text: `SaaS Glue has built a complicated system for me and it has been a pleasure
    working with them. They work quickly and communicate throughout the project,
    being quick to respond to any questions I have. I would highly recommend them.`,
  },
  //   {
  //     name: "Jonathan Ezer, PhD",
  //     company: "Kindea Labs",
  //     text: `I really enjoyed working with SaaS Glue.
  //     They understood my requirements instantly, communication was excellent,
  //     and the work they delivered was high-quality.
  //     Whenever there were issues that needed to be addressed, they addressed them
  //     quickly and correctly. I highly recommend them.`,
  //   },
  {
    name: "Greg Boyes",
    company: "FABIO",
    text: `We've been using SaaS Glue for nearly 5 years, and keep coming back to them.
    They're always patient and constructive in their feedback, accept scope tweaks as pilots evolve,
    and always deliver defect-free integrations. Thanks SaaS Glue!`,
  },
  //   {
  //     name: "Jonathan Ramm",
  //     company: "Vale Interiors",
  //     text: `SaaS Glue went above and beyond what was required, I would highly recommend them. Excellent!`,
  //   },
];

function App() {
  useLayoutEffect(() => {
    window.addEventListener("beforeunload", function () {
      window.scroll(0, 0);
    });
  });

  const scroll = useCallback(() => {
    const h = document.querySelector(".loading-container").offsetHeight;
    window.scroll({ top: h, behavior: "smooth" });
  }, []);
  //   const onAnimationOver = useCallback(() => {
  //     document.body.classList.add("body-unlock");
  //   }, []);

  return (
    <div className="screen">
      <div className="loading-container">
        <div className="logo">
          <img src={logo} alt="SAAS GLUE" />
        </div>
        <div className="hero1">
          <div className="text-top">
            <div>
              <span>Because</span>
              <span>The perfect SaaS</span>
            </div>
          </div>
          <div className="bar"></div>
          <div className="text-bottom">
            <div>
              <span>Doesn't exist!</span>
            </div>
          </div>
        </div>
        <div className="hero2">
          <span>
            We glue scattered SaaS products into
            <br />
            <i>the perfect workflow</i>
          </span>
        </div>
        <svg
          className="arrows"
          onClick={scroll}
          //   onAnimationEnd={onAnimationOver}
        >
          <path className="a1" d="M0 0 L30 32 L60 0"></path>
          <path className="a2" d="M0 20 L30 52 L60 20"></path>
          <path className="a3" d="M0 40 L30 72 L60 40"></path>
        </svg>
      </div>
      <div className="section1-container">
        <h1>Scatter nightmare? we can help</h1>
        <img
          src={bg1}
          width="500"
          height="475"
          className="section1-img"
          alt="Scattered papers"
        />
        <p>
          Meet Dan, his online store was an instant success thanks to Shopify's
          all-in-one features. But as he expanded to other sales channels, he
          now uses separate software for managing inventory across locations
          (multiple!), shipping orders, and accounting. While each solution
          addresses a specific need, Dan is overwhelmed by the lack of
          integration, his data was scattered everywhere. He's lost global
          visibility, decision-making is slow, and lean competitors are gaining
          ground.
        </p>

        <p>
          We've helped multiple businesses in Dan's situation solve workflow
          problems to achieve different degrees of integration between their
          tools. Our goal is to make your business:
        </p>
        <ul>
          <li>
            <b>Efficient:</b> use the best tool for the job without fear of
            introducting a new bottleneck
          </li>
          <li>
            <b>Automated:</b> let computers manage as much as possible, they
            make fewer mistakes so you focus on your core business
          </li>
          <li>
            <b>Connected:</b> share and consume data seamlessly, be up to date
            with your suppliers and keep your customers in the loop
          </li>
          <li>
            <b>Fast:</b> be able to make informed decisions quickly, aggregating
            data across multiple platforms is slow and tedious
          </li>
          <li>
            <b>Unique:</b> the problems you solved in unique ways made your
            success, off-the-shelf can't do it all
          </li>
        </ul>

        <p>We'd love to hear your pain points and help you solve them.</p>

        <a
          className="primary-action"
          rel="noopener noreferrer"
          target="_blank"
          href="https://cal.com/saasglue"
        >
          Book a free 30min call
        </a>
      </div>
      <div className="section2-container">
        <div className="dashed"></div>
        <div className="dashed-back"></div>
        <div className="solid"></div>
        <div className="section2-text">
          <h1>Our process</h1>
          <ul>
            <li>
              <h4>RESEARCH</h4>
              <p>We'll understand your business and your goals</p>
            </li>
            <li>
              <h4>DESIGN</h4>
              <p>Together we'll define the problems and design solutions</p>
            </li>
            <li>
              <h4>DEVELOP</h4>
              <p>We'll develop robust solutions tailored to your needs</p>
            </li>
            <li>
              <h4>TEST & EVALUATE</h4>
              <p>Validate and find new areas of improvements</p>
            </li>
            <li>
              <h4>OPTIMISE</h4>
              <p>Work on optimizations and new ideas to increase efficiency</p>
            </li>
          </ul>
        </div>
      </div>
      <div className="section3-container">
        <h1>Our services and experience</h1>
        <img
          src={bg2}
          width="500"
          height="410"
          className="section3-img"
          alt="Mesh network"
        />
        <p>
          Our extensive knowledge of automation and integration has helped
          multiple businesses build tight workflows using popular software
          solutions.
        </p>
        <p>
          Some of the projects we've done for clients cover these areas and
          services:
        </p>
        <ul>
          <li>
            <b>CRM/Accounting:</b> Xero, Linnworks, FreeAgent, Quickbooks,
            Brightpearl, KashFlow, TradeGecko, Infusionsoft
          </li>
          <li>
            <b>AI:</b> OpenAI, Gemini, LLama
          </li>
          <li>
            <b>Payments:</b> Stripe, Paypal, Adyen, Klarna, Clearpay
          </li>
          <li>
            <b>eCommerce:</b> Shopify, WooCommerce, Magento, Zen Cart, Phorest,
            Etsy
          </li>
          <li>
            <b>Shipping gateway:</b> GLS, U-Haul, 3PL Central, Mint soft
          </li>
          <li>
            <b>Social media:</b> Facebook, X, Instagram, Pinterest
          </li>
          <li>
            <b>Other:</b> Google APIs (Adwords, Analytics, Maps,...), Klaviyo,
            Twilio, Trello, FullContact, IG Markets, Bexio, Dropbox, Mailchimp,
            TrustPilot, Wufoo...
          </li>
        </ul>
      </div>
      <div className="section4-container">
        <h1>Testimonials</h1>
        <ul>
          {reviews.map((review, i) => (
            <li key={i}>
              <p>{review.text}</p>
              <h4 className="section4-name">
                {review.name}
                <span className="section4-company">{review.company}</span>
              </h4>
            </li>
          ))}
        </ul>
      </div>
      <div className="footer">
        <img src={logo} className="footer-logo" alt="SAAS GLUE" />
        <div className="footer-text">
          <h1>Let's build something amazing together</h1>
          <p>Do you have a challenge for us? Get in touch.</p>
          <a
            className="footer-link primary"
            rel="noopener noreferrer"
            target="_blank"
            href="https://cal.com/saasglue"
          >
            Book a free 30min call
          </a>
          <a className="footer-link" href="mailto:hi@saas-glue.com">
            hi@saas-glue.com
          </a>
          <p className="footer-copyright">© 2019-2024</p>
        </div>
      </div>
    </div>
  );
}

export default App;
